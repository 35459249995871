<template>
  <!-- footer -->
  <footer>
    <div class="footer-logo">
      <img src="/assets/images/logo-mini.png" class="img-responsive" />
    </div>
    <p class="text-right pull-right">
      © 2023 - Com 💕 Stimper Team <span>•</span> Todos os direitos reservados.
    </p>
  </footer>
</template>

<script>

export default {
  name: 'Footer',
  components: {
  },
  data() {
    return {
    };
  },
  mounted() {},
  computed: {
    classes() {
      return ['wizard'];
    },
    info() {
      return JSON.parse(localStorage.getItem('stimper_user'));
    },
  },
  methods: {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
