<template>
  <div class="custom-select" :tabindex="tabindex" @blur="open = false">
    <div class="selected" :class="{ open: open }" @click="open = !open">
      <div>
        <img
          :src="`/assets/images/banks/${selected.sig}.png`"
          style="width: 10%; margin-right:5px;"
        />
        <span>{{selected.name}}</span>
      </div>
    </div>
    <div class="items" :class="{ selectHide: !open }">
      <div
        @click="
          selected = { sig: 'nubank', name: 'Nubank', id: 1 };
          open = false;
          $emit('input', { sig: 'nubank', name: 'Nubank', id: 1 });
        "
      >
        <img
          :src="`/assets/images/banks/nubank.png`"
          style="width: 10%; margin-right:5px;"
        /> Nubank
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
    },
    default: {
      type: Object,
      required: false,
      default: null,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      selected: this.default
        ? this.default
        : this.options != null
        ? this.options[0]
        : null,
      open: false,
    };
  },
  mounted() {
    this.$emit("input", this.selected);
  },
};
</script>

<style scoped>
.image-item {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  background: #e8e8e8;
  overflow: hidden;
  cursor: default;
  border-radius: 50%;
}
.custom-select {
  position: relative;
  width: 100%;
  text-align: left;
  outline: none;
  height: 47px;
  line-height: 47px;
}

.custom-select .selected {
  background-color: #0a0a0a;
  border-radius: 6px;
  border: 1px solid #666666;
  color: #fff;
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
}

.custom-select .selected.open {
  border: 1px solid #ad8225;
  border-radius: 6px 6px 0px 0px;
}

.custom-select .selected:after {
  position: absolute;
  content: "";
  top: 22px;
  right: 1em;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-color: #fff transparent transparent transparent;
}

.custom-select .items {
  color: #fff;
  border-radius: 0px 0px 6px 6px;
  overflow: hidden;
  border-right: 1px solid #ad8225;
  border-left: 1px solid #ad8225;
  border-bottom: 1px solid #ad8225;
  position: absolute;
  background-color: #0a0a0a;
  left: 0;
  right: 0;
  z-index: 1;
}

.custom-select .items div {
  color: #fff;
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
}

.custom-select .items div:hover {
  background-color: #ccc;
}

.selectHide {
  display: none;
}
.custom-select {
    height: 40px;
    line-height: 35px;
}
.custom-select .selected {
  background-color: #fff;
  color: #6d819c;
  border: 1.5px solid #dcdcdc !important;
}
.custom-select .selected::after {
  position: absolute;
  content: "";
  top: 18px;
  right: 1em;
  width: 0;
  height: 0;
  border: 5px solid transparent;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
  border-color: #6d819c transparent transparent transparent;
}
.custom-select .items {
    color: #6d819c;
    background-color: #fff;
    border: 1.5px solid #dcdcdc !important;
}
.custom-select .items div {
    color: #6d819c;
}
</style>
