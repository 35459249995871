<template>
  <header>
    <div id="sidemenu">
      <button class="sidemenu__btn hide" v-on:click="navOpen=!navOpen" v-bind:class="{active:navOpen, sidemenu__btn_closed: !navOpen}">
        <span class="top"></span>
        <span class="mid"></span>
        <span class="bottom"></span>
      </button>
      <transition name="translateX">
        <nav v-show="navOpen">
          <div class="sidemenu__wrapper">
            <ul class="sidemenu__list" v-on:click="navOpen=false">
              <li class="sidemenu__item">
                <router-link
                  :class="{ active: menu == 'cashflow' }"
                  :to="'/'">
                  Visão Geral
                </router-link>
              </li>
              <li class="sidemenu__item">
                <router-link
                  class="dropdown-item header-menu-item selected"
                  :class="{ active: menu == 'transactions' }"
                  :to="'/transactions'">
                  Transações
                </router-link>
              </li>
              <li class="sidemenu__item">
                <router-link
                  class="dropdown-item header-menu-item"
                  :class="{ liactive: menu == 'cashflow' }"
                  :to="'/cashflow'">
                  Fluxo de caixa
                </router-link>
              </li>
              <li class="sidemenu__item">
                <router-link
                  class="dropdown-item header-menu-item"
                  :class="{ liactive: menu == 'billing' }"
                  :to="'/billing'">
                  Contas Fixas
                </router-link>
              </li>
              <li class="sidemenu__item" :class="{ item_active: menu == 'budget' }">
                <router-link
                  class="dropdown-item header-menu-item"
                  :class="{ liactive: menu == 'budget' }"
                  :to="'/budget'">
                  Orçamentos
                </router-link>
              </li>
              <li class="sidemenu__item">
                <router-link
                  class="dropdown-item header-menu-item"
                  :class="{ liactive: menu == 'account' }"
                  :to="'/account'">
                  Contas
                </router-link>
              </li>
              <li class="sidemenu__item">
                <router-link
                  class="dropdown-item header-menu-item"
                  :class="{ liactive: menu == 'transfer' }"
                  :to="'/transfer'">
                  Transferência
                </router-link>
              </li>
              <li class="sidemenu__item hide">
                <router-link
                  class="dropdown-item header-menu-item"
                  :class="{ liactive: menu == 'providers' }"
                  :to="'/providers'">
                  Fornecedores
                </router-link>
              </li>
              <li class="sidemenu__item hide">
                <router-link
                  class="dropdown-item header-menu-item"
                  :class="{ liactive: menu == 'clients' }"
                  :to="'/clients'">
                  Clientes
                </router-link>
              </li>
              <li class="sidemenu__item">
                <router-link
                  class="dropdown-item header-menu-item"
                  :class="{ liactive: menu == 'import' }"
                  :to="'/import'">
                  Importar
                </router-link>
              </li>
              <li class="sidemenu__item">
                <router-link
                  class="dropdown-item header-menu-item selected"
                  :class="{ active: menu == 'card' }"
                  :to="'/card'">
                  Cartões
                </router-link>
              </li>
            </ul>
          </div>
        </nav>
      </transition>
    </div>
    <!-- Humbager -->
    <div class="humbager hide">
      <i class="mdi mdi-menu"></i>
    </div>
    <!-- logo -->
    <div class="branding">
      <a href="/">
        <img
          src="/assets/images/icon_fav.png"
          class="img-responsive"
          style="height: 38px"
        />
      </a>
    </div>

    <!-- Navigation -->
    <nav class="navigation header-nav">
      <ul class="nav navbar-nav menu-content">
        <li :class="{ active: menu == '' }">
          <router-link
            class="dropdown-item header-menu-item selected"
            :class="{ active: menu == 'cashflow' }"
            :to="'/'">
            Visão Geral
          </router-link>
        </li>
        <li :class="{ active: menu == 'transactions' }">
          <router-link
            class="dropdown-item header-menu-item selected"
            :class="{ active: menu == 'transactions' }"
            :to="'/transactions'">
            Transações
          </router-link>
        </li>
        <li :class="{ active: menu == 'billing' }">
          <router-link
            class="dropdown-item header-menu-item selected"
            :class="{ active: menu == 'billing' }"
            :to="'/billing'">
            Contas Fixas
          </router-link>
        </li>
        <li class="drop-down-hover" :class="{ active: menu == 'bill' || menu == 'cashflow' || menu == 'account' || menu == 'subscriptions' || menu == 'budget' || menu == 'import' || menu == 'transfer' }">
          <a href="#" class="selected">Financeiro</a>
          <div class="dropdown-menu">
            <router-link
              class="dropdown-item header-menu-item"
              :class="{ liactive: menu == 'subscriptions' }"
              :to="'/subscriptions'">
              Assinaturas
            </router-link>
            <router-link
              class="dropdown-item header-menu-item"
              :class="{ liactive: menu == 'cashflow' }"
              :to="'/cashflow'">
              Fluxo de Caixa
            </router-link>
            <router-link
              class="dropdown-item header-menu-item"
              :class="{ liactive: menu == 'budget' }"
              :to="'/budget'">
              Orçamentos
            </router-link>
            <router-link
              class="dropdown-item header-menu-item"
              :class="{ liactive: menu == 'goal' }"
              :to="'/goal'">
              Objetivos
            </router-link>
            <router-link
              class="dropdown-item header-menu-item"
              :class="{ liactive: menu == 'account' }"
              :to="'/account'">
              Contas
            </router-link>
            <router-link
              class="dropdown-item header-menu-item"
              :class="{ liactive: menu == 'transfer' }"
              :to="'/transfer'">
              Transferência
            </router-link>
            <router-link
              class="dropdown-item header-menu-item hide"
              :class="{ liactive: menu == 'providers' }"
              :to="'/providers'">
              Fornecedores
            </router-link>
            <router-link
              class="dropdown-item header-menu-item hide"
              :class="{ liactive: menu == 'clients' }"
              :to="'/clients'">
              Clientes
            </router-link>
            <router-link
              class="dropdown-item header-menu-item"
              :class="{ liactive: menu == 'import' }"
              :to="'/import'">
              Importar
            </router-link>
          </div>
        </li>
        <li :class="{ active: menu == 'card' }">
          <router-link
            class="dropdown-item header-menu-item selected"
            :class="{ active: menu == 'card' }"
            :to="'/card'">
            Cartões
          </router-link>
        </li>
        <li class="close-menu">
          <a href=""><i class="mdi mdi-close-circle-outline"></i> Fechar</a>
        </li>
      </ul>
    </nav>

    <!-- Right content -->
    <div class="header-right">
      <div
        class="
          dropdown
          hidden-sm hidden-xs
          income-expense-tour
          btn-transactions-menu
        "
        :class="{'open': activeDropDownTransaction}" v-click-outside-element="outsideTransactionClick"
      >
        <button
          class="btn btn-green dropdown-toggle color-white btn-green-shadow"
          type="button"
          data-toggle="dropdown"
          style="height: 45px"
          @click.prevent="activeDropDownTransaction = !activeDropDownTransaction"
        >
          <span class="to-left">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="#fff"
              viewBox="0 0 20 20"
            >
              <path
                fill-rule="evenodd"
                d="M10.833 9.167V5H9.167v4.167H5v1.666h4.167V15h1.666v-4.167H15V9.167h-4.167zM10 20C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10z"
              ></path>
            </svg>
          </span>
        </button>
        <ul class="dropdown-menu">
          <li role="presentation">
            <a role="menuitem" @click="createTransaction('create-transaction-modal', 'expense', false)">
              <i class="mdi mdi-chevron-right"></i>Nova despesa</a
            >
          </li>
          <li role="presentation">
            <a role="menuitem" @click="createTransaction('create-transaction-modal', 'expense', true, 'P')">
              <i class="mdi mdi-chevron-right"></i>Despesa de cartão</a
            >
          </li>
          <li role="presentation">
            <a role="menuitem" @click="createTransaction('create-transaction-modal', 'income', false)">
              <i class="mdi mdi-chevron-right"></i>Nova receita</a
            >
          </li>
          <li role="presentation">
            <a role="menuitem" @click="createTransaction('create-transaction-modal', 'transfer', false)">
              <i class="mdi mdi-chevron-right"></i>Transferência</a
            >
          </li>
        </ul>
      </div>
      <div class="dropdown" :class="{'open': activeDropDown}" v-click-outside-element="outsideClick">
        <span class="dropdown-toggle" data-toggle="dropdown" @click.prevent="activeDropDown = !activeDropDown">
          <span class="avatar">
            <img
              :src="info.avatar ? info.avatar : `/assets/images/avatar.png`"
              class="img-circle"
            />
            &nbsp;
          </span>
          <span class="profile-name">
            <span class="hidden-xs">{{info.fname}}</span>
            <i class="mdi mdi-menu-down"></i>
          </span>
        </span>
        <ul
          class="dropdown-menu profile-menu"
          role="menu"
          aria-labelledby="menu1"
        >
          <li role="presentation">
            <router-link
              class="menuitem selected"
              :class="{ active: menu == 'settings' }"
              :to="'/settings'">
              <i class="mdi mdi-settings"></i> Configurações
            </router-link>
          </li>
          <li role="presentation">
            <router-link
              class="menuitem selected"
              :class="{ active: menu == 'budget' }"
              :to="'/budget'">
              <i class="mdi mdi-clipboard-text"></i> Orçamento
            </router-link>
          </li>
          <li role="presentation hide" style="display: none;">
            <router-link
              class="menuitem selected"
              :class="{ active: menu == 'premium' }"
              :to="'/premium'">
              <i class="mdi mdi-sync"></i>Premium
            </router-link>
          </li>
          <li role="presentation">
            <a role="menuitem" href="/settings?tab=category"><i class="mdi mdi-file-tree"></i> Categorias</a>
          </li>
          <li role="presentation">
            <a role="menuitem" @click="logout"><i class="mdi mdi-logout" @click="logout"></i> Sair</a>
          </li>
        </ul>
      </div>
    </div>
  </header>
</template>

<script>
import moment from 'moment-timezone';

export default {
  name: 'Toolbar',
  components: {
  },
  data() {
    return {
      navOpen: false,
      activeDropDown: false,
      activeDropDownTransaction: false,
    };
  },
  mounted() {
  },
  created() {
  },
  computed: {
    classes() {
      return ['wizard'];
    },
    info() {
      return JSON.parse(localStorage.getItem('stimper_user'));
    },
    menu() {
      console.log(this.$route.path.split('/')[1]);
      return this.$route.path.split('/')[1];
    }
  },
  methods: {
    createTransaction(id, type, isCard, bill_type = 'M') {
      const data = {
        isCard: isCard,
        type: type,
        card: null,
        bill_type: {
          type: bill_type,
        },
        is_recurrence: false,
        is_subscription: false,
        invoice_pay: false,
        installments: 1,
        invoice: {},
        account: null,
        transfer: type == 'transfer' ? true : false,
        check_sub: true,
        date: moment(new Date()).format('DD/MM/YYYY'),
      };
      this.$root.$emit('show.modal', id, data);
    },
    outsideClick() {
      this.activeDropDown = false;
    },
    outsideTransactionClick() {
      this.activeDropDownTransaction = false;
    },
    logout() {
      this.$store.dispatch('auth/logout').then(() => {
        this.$router.push('/login');
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  #sidemenu {
    position: absolute;
    height: 100%;
  }
  .item_active {
    background: lightgrey !important;
    color: dimgrey;
  }
  #sidemenu {
	nav {
		width: 200px;
		// height: calc(100% - #{$headerHeight} - #{$footerHeight});
		background: grey;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 99;
    height: 100%;
    -webkit-box-shadow: 11px 2px 9px 0px rgba(0,0,0,0.24); 
    box-shadow: 11px 2px 9px 0px rgba(0,0,0,0.24);
		// box-shadow: 2px 0 3px$grey-6;
		overflow-y: scroll;
	}
	.sidemenu {
		&__btn {
			display: block;
			width: 50px;
			height: 50px;
			background: grey;
			border: none;
			position: relative;
			z-index: 100;
			appearance: none;
			cursor: pointer;
			outline: none;

			span {
				display: block;
				width: 20px;
				height: 2px;
				margin: auto;
				background: white;
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				transition: all .4s ease;

				&.top {
					transform: translateY(-8px);
				}

				&.bottom {
					transform: translateY(8px);
				}
			}
			&.active{
				.top {
					transform: rotate(-45deg);
				}
				.mid{
					transform: translateX(-20px) rotate(360deg);
					opacity: 0;
				}
				.bottom {
					transform: rotate(45deg);
				}
			}

		}

		&__wrapper {
      padding-top: 50px;
    }

		&__list {
			padding-top: 50px;
      list-style:none;
      padding: 0;
      margin: 0;
		}

		&__item {
			a {
        text-decoration: none;
				line-height: 1.6em;
				font-size: 1.6em;
				padding: .5em;
				display: block;
				color: white;
				transition: .4s ease;
        font-size: 20px;

				&:hover {
					background: lightgrey;
					color: dimgrey;
				}
			}
		}
	}
  .sidemenu__btn {
    background: none !important;
  }
  .sidemenu__btn_closed {
    background: none !important;
    span {
      background: grey !important;
    }
  }
  .sidemenu-closed {
    background: white;
    span { 
      color: grey;
    }
  }
}

.translateX-enter{
	transform:translateX(-200px);
	opacity: 0;
}

.translateX-enter-active,.translateX-leave-active{
	transform-origin: top left 0;
	transition:.2s ease;
}

.translateX-leave-to{
	transform: translateX(-200px);
	opacity: 0;
}

</style>
