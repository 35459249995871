<template>
  <div
    :id="modalId"
    :class="classes"
    :style="this.styles"
    tabIndex="-1"
    role="dialog"
  >
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" v-if="form.type == 'expense' && !form.isCard && !form.invoice_pay">Nova despesa</h5>
          <h5 class="modal-title" v-if="form.type == 'income' && !form.isCard && !form.invoice_pay">Nova receita</h5>
          <h5 class="modal-title" v-if="form.type == 'income' && form.invoice_refund">Reembolso</h5>
          <h5 class="modal-title" v-if="form.type == 'transfer'">Transferência</h5>
          <h5 class="modal-title" v-if="form.type == 'expense' && form.isCard && !form.invoice_pay">Nova despesa de cartão</h5>
          <h5 class="modal-title" v-if="form.type == 'expense' && form.invoice_pay">Pagar fatura</h5>
          <button type="button" class="close" @click="hide(true)" aria-label="Close">
            <svg aria-hidden="true" style="width: 15px;" focusable="false" data-prefix="fas" data-icon="xmark" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-xmark fa-xl"><path fill="currentColor" d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" class=""></path></svg>
          </button>
        </div>
        <form @submit.prevent="create">
          <div class="modal-body">
            <div class="form-group">
              <div class=row v-if="form.type != 'transfer' && !form.isCard && !form.invoice_pay && !form.transfer">
                <div class="col-md-12">
                  <div class="slide-tab-header">
                    <slide-tab :tabindex="miniTab.tab" tabPositionMax="50" firstTabColor="last-tab-color" lastTabColor="first-tab-color" @changeTabCallBack="changeTab"/>
                  </div>
                  <hr>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <label>Descrição</label>
                  <input v-model="form.note" class="form-control">
                </div>
              </div>
              <div class="row m-top-10">
                <div class="col-md-6">
                  <label>Valor*</label>
                  <money v-model="form.value" v-bind="money" class="form-control"></money> 
                </div>
                <div class="col-md-6">
                  <label>Data*</label>
                  <date-picker v-model="form.date" :lang="lang"  value-type="format" @change="changeDate" format="DD/MM/YYYY" :input-class="`form-control`"></date-picker>
                </div>
              </div>
              <div class="row m-top-10">
                <div class="col-md-12" v-if="form.isCard && !form.invoice_pay">
                  <label>Cartão</label>
                  <div class="row">
                    <div class="col-xs-10 col-sm-10 p-right-0">
                      <select-input
                        label="name"
                        :reduce="(field) => field"
                        v-model="form.card"
                        :options="cards"
                        v-on:option:selected="changeCard"
                        :clearable="true"
                      />
                    </div>
                    <div class="col-xs-2 col-sm-2 p-top-4">
                      <img @click="createCard('create-card-modal')" src="/assets/images/icons/png/btn_plus.png" class="opacity-hover c-pointer" style="width: 25px;">
                    </div>
                  </div>
                </div>
              </div>
              <div class="row m-top-10" v-if="form.isCard && !form.invoice_pay">
                <div class="col-sm-12">
                  <span class="stpe-component_dropdown drop-down-select-month stpe-ng-animate-if-slide ng-binding ng-scope">
                    Fatura de  <a class="stpe-dropdown-action ng-binding" href="">{{ form.invoice.date | moment("MMMM/YYYY") }}</a>
                    <div class="stpe-dropdown-area">
                      <div class="stpe-dropdown-box stpe-dropdown-text-center" style="top:39px;">
                        <span class="icon-caret-up "><i class="fa fa-caret-up"></i></span>
                        <ul>
                          <li class="stpe-dropdown-li" :class="{ 'stpe-invoice-active': invoice.selected }" v-for="(invoice, i) in this.invoices" :key="i">
                            <a @click="changeInvoice(i)"><i class="fas fa-check" v-if="invoice.selected"></i> &nbsp; {{ invoice.date | moment("MMMM/YYYY") }}</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </span>
                </div>
              </div>
              <div class="row" v-if="form.transfer">
                <div class="col-md-12">
                  <label>Conta*</label>
                  <div class="row">
                    <div class="col-xs-10 col-sm-10 p-right-0">
                      <select-input
                        label="name"
                        :reduce="(field) => field.id"
                        v-model="form.from"
                        :options="accounts"
                        :clearable="true"
                      />
                    </div>
                    <div class="col-xs-2 col-sm-2 p-top-4">
                      <img @click="createAccount('create-account-modal')" src="/assets/images/icons/png/btn_plus.png" class="opacity-hover c-pointer" style="width: 25px;">
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" v-else>
                <div class="col-md-12" v-if="!form.isCard || form.invoice_pay">
                  <label>Conta*</label>
                  <div class="row">
                    <div class="col-xs-10 col-sm-10 p-right-0">
                      <select-input
                        label="name"
                        :reduce="(field) => field.id"
                        v-model="form.account"
                        :options="accounts"
                        :clearable="true"
                      />
                    </div>
                    <div class="col-xs-2 col-sm-2 p-top-4">
                      <img @click="createAccount('create-account-modal')" src="/assets/images/icons/png/btn_plus.png" class="opacity-hover c-pointer" style="width: 25px;">
                    </div>
                  </div>
                </div>
              </div>
              <div class="row m-top-10" v-if="form.transfer">
                <div class="col-md-12">
                  <label>Conta destino*</label>
                  <select-input
                    label="name"
                    :reduce="(field) => field.id"
                    v-model="form.to"
                    :options="accounts"
                    :clearable="true"
                  />
                </div>
              </div>
              <div class="row" v-if="form.type != 'transfer'">
                <div class="col-md-12">
                  <hr class="divider-category" style="margin-bottom: 0px;">
                </div>
                <div class="col-md-12  m-top-10">
                  <label>Categoria*</label>
                  <div class="row">
                    <div class="col-xs-10 col-sm-10 p-right-0">
                      <CategorySelect
                        :options="categories"
                        class="select"
                        @input="changeParent($event)"
                      />
                    </div>
                    <div class="col-xs-2 col-sm-2 p-top-4">
                      <img @click="createCategory('create-category-modal')" src="/assets/images/icons/png/btn_plus.png" class="opacity-hover c-pointer" style="width: 25px;">
                    </div>
                  </div>
                </div>
                <div class="col-md-12 " v-if="subcategories.length > 0" style="margin-top: 15px;">
                  <label>Subcategoria</label>
                  <div class="card" style="margin-bottom: 0px; padding: 5px;">
                    <div>
                      <ul class="ul-category-icon">
                        <li v-tooltip.top="item.name" v-for="(item, i) in subcategories" :key="i" @click="choseSubcategorie(item)">
                          <div class="ul-category-icon-item to-left" :style="`background: ${item.color}`" :class="{ active: item.selected }">
                            <img :src="`/assets/images/icons/png/${item.icon}.png`">
                            <i class="fa fa-check check-selected"></i>
                          </div>
                          <div style="clear: both;"></div>
                        </li>
                      </ul>
                    </div>
                    <div style="clear: both;"></div>
                  </div>
                </div>
                <div class="col-md-12">
                  <hr class="divider-category">
                </div>
              </div>
              <div class="row m-top-10 hide" v-if="!form.invoice_pay && !form.transfer && form.type == 'expense'">
                <div class="col-md-12">
                  <label>Fornecedor</label>
                  <div class="row">
                    <div class="col-xs-10 col-sm-10 p-right-0">
                      <select-input
                        label="name"
                        :reduce="(field) => field.id"
                        v-model="form.provider_id"
                        :options="providers"
                        :clearable="true"
                      />
                    </div>
                    <div class="col-xs-2 col-sm-2 p-top-4">
                      <img @click="createProvider('create-provider-modal')" src="/assets/images/icons/png/btn_plus.png" class="opacity-hover c-pointer" style="width: 25px;">
                    </div>
                  </div>
                </div>
              </div>
              <div class="row m-top-10 hide" v-if="!form.invoice_pay && !form.transfer && form.type == 'income'">
                <div class="col-md-12">
                  <label>Cliente</label>
                  <div class="row">
                    <div class="col-xs-10 col-sm-10 p-right-0">
                      <select-input
                        label="name"
                        :reduce="(field) => field.id"
                        v-model="form.client_id"
                        :options="clients"
                        :clearable="true"
                      />
                    </div>
                    <div class="col-xs-2 col-sm-2 p-top-4">
                      <img @click="createClient('create-client-modal')" src="/assets/images/icons/png/btn_plus.png" class="opacity-hover c-pointer" style="width: 25px;">
                    </div>
                  </div>
                </div>
              </div>
              <div class="row m-top-10" v-if="!form.invoice_pay && !form.transfer">
                <div class="col-md-6">
                  <input type="checkbox" v-model="form.is_recurrence" class="to-left" id="is_recurrence" @change="recorrenceChange">
                  <label style="margin-top: 10px;" for="is_recurrence" class="to-left">Recorrente</label>
                </div>
                <div class="col-md-6" v-if="form.isCard">
                  <input type="checkbox" v-model="form.is_subscription" class="to-left" id="is_subscription" @change="subscriptionChange">
                  <label style="margin-top: 10px;" for="is_subscription" class="to-left">Assinatura</label>
                </div>
              </div>
              <div class="row  m-top-10" v-if="form.is_recurrence || form.is_subscription">
                <div class="col-sm-12 type-block show">
                  <br>
                  <div class="card">
                    <div class="card-header">
                      Configurar Recorrência
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-12">
                          <label>Recorrência*</label>
                          <select class="form-control select2" name="type" v-model="form.bill_type.type" v-if="form.is_recurrence && !form.isCard">
                            <option value="P">Parcelada</option>
                            <option value="D">Diário</option>
                            <option value="M" selected>Mensal</option>
                            <option value="A">Anual</option>
                            <option value="E">Semanal</option>
                          </select>
                          <select class="form-control select2" name="type" v-model="form.bill_type.type" v-if="!form.is_subscription && form.is_recurrence && form.isCard">
                            <option value="P" selected>Parcelada</option>
                          </select>
                          <select class="form-control select2" name="type" v-model="form.bill_type.type" v-if="form.is_subscription && form.isCard">
                            <option value="A">Anual</option>
                            <option value="M" selected>Mensal</option>
                          </select>
                        </div>
                        <div class="col-md-12 block-date hide" v-if="form.bill_type.type == 'Q'">
                          <label>Dia do vencimento*</label>
                          <input type="number" class="form-control" v-model="form.due_day" name="due_day" placeholder="Dia do vencimento">
                        </div>
                        <div class="col-md-12 secound-block hide" v-if="form.bill_type.type == 'Q'">
                          <label>Dia do 2º vencimento*</label>
                          <input type="number" class="form-control" v-model="form.second_due_day" name="second_due_day" placeholder="Dia do 2º vencimento">
                        </div>
                        <div class="col-md-12 week-day-block hide" v-if="form.bill_type.type == 'E'">
                          <label>Dia da semana</label>
                          <select class="form-control select2" name="week_day" v-model="form.week_day">
                            <option value="0" selected="">Domingo</option>
                            <option value="1">Segunda</option>
                            <option value="2">Terça</option>
                            <option value="3">Quarta</option>
                            <option value="4">Quinta</option>
                            <option value="5">Sexta</option>
                            <option value="6">Sábado</option>
                          </select>
                        </div>  
                        <div class="col-md-12 hide" v-if="form.bill_type.type != 'P'">
                          <label>Data Limite*</label>
                          <date-picker v-model="form.date_limit" :lang="lang"  value-type="format" format="DD/MM/YYYY" :input-class="`form-control`"></date-picker>
                        </div>
                        <div class="col-md-12 m-top-5" v-if="form.is_recurrence">
                          <label>Quantidade</label>
                          <input type="number" class="form-control" name="installments" placeholder="Quantidade" v-model="form.installments" minlength="1">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row m-top-10">
                <p v-if="errors.length" style="margin: 0px;">
                  <ul class="alert-error">
                    <li v-for="error in errors" :key="error">
                      <div class="alert alert-danger">{{ error }}</div>
                    </li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="submit"
              class="btn btn-success btn-green-shadow"
              :class="{ 'qt-loader qt-loader-mini qt-loader-right': isSending }"
              :disabled="isSending"
            >
              Salvar
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from '@/mixins/Modal';
import { Money } from 'v-money';
import AppService from '@/services/app.service';
import SelectInput from '@/components/SelectInput.vue';
import moment from 'moment-timezone';
import {ptBR} from 'vuejs-datepicker/dist/locale'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/zh-cn';
import SlideTab from '@/components/SlideTab';
import CategorySelect from '@/components/CategorySelect';

export default {
  mixins: [Modal],
  props: ['id'],
  components: {
    Money,
    SelectInput,
    DatePicker,
    SlideTab,
    CategorySelect,
  },
  data() {
    return {
      miniTab: {
        tab: 0,
        indicator: 0,
      },
      tabIncome: 'expense',
      tabColor: '#ff1a1a',
      ptBR: ptBR,
      invoices: [],
      categories: [],
      cards: [],
      date: null,
      errors: [],
      lang: {
        formatLocale: {
          // MMMM
          months: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
          // MMM
          monthsShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Otu', 'Nov', 'Dez'],
          // dddd
          weekdays: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
          // ddd
          weekdaysShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
          // dd
          weekdaysMin: ['Do', 'Se', 'Te', 'Qua', 'Qui', 'Sex', 'Sa'],
        },
        yearFormat: 'YYYY',
        monthBeforeYear: true,
      },
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false
      },
      form: {
        bill_type: {
          type: 'M',
        },
        invoice: {},
        check_sub: true,
        is_recurrence: false,
        account: null,
        card: null,
        date: moment(new Date()).format('DD/MM/YYYY'),
        device: 'web',
        provider_id: null,
      },
      isSending: false,
      accounts: [],
      providers: [],
      clients: [],
      subcategories: [],
      from: '',
      to: '',
    };
  },
  mounted() {
    this.$root.$on('show.modal', this.checkTab);
  },
  beforeDestroy() {
    this.data = null;
  },
  created() {
    this.$forceUpdate();
  
    this.getWallets();
    this.getCards();
    this.getProvider();
    this.getClient();
    this.requestCategories(this.formatType())

    this.from = `${moment(new Date()).format('YYYY-MM')}-01`;
    this.to = moment(this.from, "YYYY-MM-DD").endOf('month');
    this.callInvoices();

    this.$root.$on('save.category', this.callCategory);
    this.$root.$on('register.account', this.getWallets);
    this.$root.$on('register.provider', this.getProvider);
    this.$root.$on('register.client', this.getClient);
    this.$root.$on('register.card', this.getCards);
  },
  methods: {
    choseSubcategorie(item) {
      let selected = !item.selected;
      this.subcategories.forEach(element => {
        element.selected = false;
      });
      item.selected = selected;
      if(selected) {
        this.form.category = item.id;
      } else {
        this.form.category = item.parent_id;
      }
      this.$forceUpdate();
    },
    subscriptionChange() {
      this.form.is_recurrence = false;
      this.form.bill_type.type = 'M';
    },
    recorrenceChange() {
      this.form.is_subscription = false;
    },
    changeParent(parent) {
      this.subcategories = [];
      if(parent == null) {
        this.form.category = null;
        return;
      }
      this.form.category = parent.id;

      let newChildrens = [];
      newChildrens     = newChildrens.concat(parent.childrens);
      let indexSelf = newChildrens.findIndex((x) => x.id === parent.id );
      newChildrens.splice(indexSelf, 1);

      this.subcategories = this.subcategories.concat(newChildrens);
    },
    requestCategories(type, callback = null) {
      this.subcategories = [];
      this.categories = [];
      if(this.form.transfer) {
        type = 'Expense';
      }
      if(callback != null) {
        this.form.category = callback.category.id;
        this.$root.$emit('select.category', callback.category);
      }
      AppService.getCategoriesHide(type).then(
          (response) => {
            this.categories = response.categories;
            this.$forceUpdate();
          },
          (error) => {
            console.log(error);
            this.content = error;
          }
        );
    },
    checkTab(id = null) {
      if(id == this.modalId) {
        this.$forceUpdate();
        //this.form.date = moment(new Date()).format('DD/MM/YYYY');
        if (this.data) {
          if(this.data.type != null) {
            this.$root.$emit('slide_tab.change', this.data.type == 'expense' ? 0 : 1);
            this.requestCategories(this.formatType());
          }
          const newDate = moment(this.form.date, "DD/MM/YYYY").format('YYYY-MM-DD');
          if (!this.form.invoice_pay && this.form.isCard && this.form.card) {
            AppService.getInvoices({
              card_id: this.form.card.id,
              offset: true,
              transaction_date: newDate,
            }).then(
              (response) => {
                this.invoices = response.invoices;
                response.invoices.forEach(invoice => {
                  if (invoice.selected) {
                    this.form.invoice = invoice;
                  }
                });
              },
              (error) => {
                console.log(error);
                this.content = error;
              },
            );
          }
        }
      }
    },
    changeTab(tab, indicator) {
      this.miniTab.tab = tab;
      this.miniTab.indicator = indicator;
    
      switch (tab) {
        case 0:
          this.tabColor = '#ff1a1a';
          this.tabIncome = 'expense';
          this.form.type = 'expense';
          break;
        case 1:
          this.tabColor = '#18b272';
          this.tabIncome = 'income';
          this.form.type = 'income';
          break;
        default:
          break;
      }
      if(this.form.transfer) {
        this.form.type = 'transfer';
      }
      this.form.category = null;
      this.requestCategories(this.formatType());
    },
    callCategory(callback = null) {
      this.requestCategories(this.formatType(), callback);
    },
    getProvider(callback = null) {
      if(callback) {
        this.form.provider_id = callback.provider.id;
      }
      AppService.getProvider().then(
        (response) => {
          if (response.providers) {
            this.providers = response.providers;
          }
        },
        (error) => {
          console.log(error);
          this.content = error;
        },
      );
    },
    getClient(callback = null) {
      if(callback != null) {
        this.form.client_id = callback.client.id;
      }
      AppService.getClient().then(
        (response) => {
          if (response.clients) {
            this.clients = response.clients;
          }
        },
        (error) => {
          console.log(error);
          this.content = error;
        },
      );
    },
    getCards(callback = null) {
      if(callback) {
        this.form.card = callback.card;
        this.callInvoices();
      }
      AppService.getCards({
        from: this.from,
        to: this.to,
      }).then(
        (response) => {
          this.cards = response.cards;
        },
        (error) => {
          console.log(error);
          this.content = error;
        },
      );
    },
    getWallets(callback = null) {
      if(callback) {
        this.form.account = callback.wallet.id;
        this.form.from    = callback.wallet.id;
      }
      AppService.getWallets({}).then(
        (response) => {
          this.accounts = response.wallets;
        },
        (error) => {
          console.log(error);
          this.content = error;
        },
      );
    },
    callInvoices() {
      if (this.form.card) {
        const newDate = moment(this.form.date, "DD/MM/YYYY").format('YYYY-MM-DD');
        AppService.getInvoices({
          card_id: this.form.card.id,
          offset: true,
          transaction_date: newDate,
        }).then(
          (response) => {
            this.invoices = response.invoices;
            response.invoices.forEach(invoice => {
              if (invoice.selected) {
                this.form.invoice = invoice;
              }
            });
          },
          (error) => {
            console.log(error);
            this.content = error;
          },
        );
      }
    },
    changeCard() {
      this.callInvoices();
    },
    formatType() {
      let formSurname = '';
      if (this.form.type) {
        formSurname = this.form.type.toString()
        formSurname = formSurname.charAt(0).toUpperCase() + formSurname.slice(1)
      }
      return formSurname;
    },
    changeInvoice(index) {
      for (let i = 0; i < this.invoices.length; i++) {
        this.invoices[i].selected = false;
      }
      this.invoices[index].selected = true;
      this.form.invoice = this.invoices[index];
      this.$forceUpdate();
    },
    changeDate(date) {
      const newDate = moment(date, "DD/MM/YYYY").format('YYYY-MM-DD');
      if (!this.form.invoice_pay && this.form.isCard && this.form.card) {
        AppService.getInvoices({
          card_id: this.form.card.id,
          offset: true,
          transaction_date: newDate,
        }).then(
          (response) => {
            this.invoices = response.invoices;
            response.invoices.forEach(invoice => {
              if (invoice.selected) {
                this.form.invoice = invoice;
              }
            });
          },
          (error) => {
            console.log(error);
            this.content = error;
          },
        );
      }
    },
    changeBrand(brand) {
      console.log(brand);
      this.form.brand = brand;
    },
    create() {
      let valid = true;
      if (this.form.account == null && !this.form.isCard && !this.form.transfer) {
        this.$toast.show({
          title: 'Erro',
          content: 'Escolha uma conta',
          type: 'error',
        });
        valid = false;
      }

      if (this.form.category == null && this.form.type != 'transfer') {
        this.$toast.show({
          title: 'Erro',
          content: 'Escolha uma categoria',
          type: 'error',
        });
        valid = false;
      }

      if(valid) {
        this.isSending = true;
        if (this.form.transfer) {
          this.addTransfer();
        } else {
          AppService.addTransaction(this.form).then(
            (response) => {
              if (response.status == 301) {
                this.$router.push({ name: 'Premium', params: { expire: true }});
              }
              if(response.status == 200) {
                this.isSending = false;
                this.$toast.show({
                  title: 'Sucesso',
                  content: 'Transação registrada!',
                  type: 'success',
                });
                this.$root.$emit('register.transaction', this.form.type);
                this.$root.$emit('select.category', null);
              }
              this.hide(true);
            },
            (error) => {
              console.log(error);
              this.content = error;
              this.isSending = false;
              this.$toast.show({
                title: 'Erro',
                content: 'Erro interno, por favor tente novamente mais tarde',
                type: 'error',
              });
            },
          );
        }
        return true;
      }

      this.errors = [];
    },
    addTransfer() {
      this.isSending = true;
      AppService.addTransfer(this.form).then(
        (response) => {
          if (response.status == 301) {
            this.$router.push({ name: 'Premium', params: { expire: true }});
          }
          if(response.status == 200) {
            this.isSending = false;
            this.$toast.show({
              title: 'Sucesso',
              content: 'Transferência registrada!',
              type: 'success',
            });
            this.$root.$emit('register.transaction');
          }
          this.hide(true);
        },
        (error) => {
          console.log(error);
          this.content = error;
          this.isSending = false;
          this.$toast.show({
            title: 'Erro',
            content: 'Erro interno, por favor tente novamente mais tarde',
            type: 'error',
          });
        },
      );
    },
    createCategory(id) {
      const data = {
        type: this.formatType(),
        color: '#7b93a4',
        icon: 'baby-buggy',
        limit: 1,
      };
      this.$root.$emit('show.modal', id, data);
    },
    createAccount(id) {
      const data = {
        limit: 1,
        color: '#7b93a4'
      };
      this.$root.$emit('show.modal', id, data);
    },
    createCard(id) {
      const data = {
        limit: 1,
        brand: { sig: 'visa', name: 'Visa', id: 1 }
      };
      this.$root.$emit('show.modal', id, data);
    },
    createProvider(id) {
      const data = {
        limit: 1,
      };
      this.$root.$emit('show.modal', id, data);
    },
    createClient(id) {
      const data = {
        limit: 1,
      };
      this.$root.$emit('show.modal', id, data);
    },
  },
};
</script>

<style lang="scss" scoped>
textarea:not(:last-child) {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.textarea-information {
  border: 1px solid #dfe7f3;
  border-top: none;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  padding: 0.2rem 0.5rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
textarea:focus + .textarea-information {
  border: 1px solid #635ebe;
  border-top: none;
}

.actions-var {
  position: relative !important;
}
.emoji-color {
  color: #ccd2dc !important;
}
.actions-var-cancel {
  text-align: center;
  color: #4d5a68;
  border-top: 1px solid rgba(234, 243, 253, 0.7);
  padding-top: 5px;
  padding-bottom: 0px;
}
.actions-icon {
  font-size: 14px !important;
}
.actions-var a {
  cursor: pointer;
}
.fr-wrapper > *:first-child {
  display: none;
}
.fr-wrapper .fr-element {
  display: block;
}
.second-toolbar a {
  visibility: hidden;
}
#cke_673 {
  display: none !important;
}
.cke_toolbar_last {
  display: none;
}
.modal-body {
  padding: 25px;
}
.popup-icons {
  height: 50px;
}
.popup-icons .stpe-icons-center {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 64px;
  margin: auto;
}
.popup-icons .stpe-icons-center a.stpe-logo-banks {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  background: #fff;
  overflow: hidden;
  cursor: default;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.popup-icons .stpe-icons-center a.stpe-logo-banks img {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 60px;
  height: 60px;
  margin: auto;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.stpe-component_dropdown .stpe-dropdown-area {
  display: none;
  position: absolute;
  top: 15px;
  left: 120px;
  width: 190px;
  margin-top: 4px;
  margin-left: -95px;
  padding-top: 12px;
  z-index: 2;
}
.drop-down-select-month:hover .stpe-dropdown-area{
  display: block;
}
.stpe-component_dropdown .stpe-dropdown-area .stpe-dropdown-box {
  background: #fff;
  -webkit-box-shadow: 0 2px 20px rgba(0,0,0,.15);
  -moz-box-shadow: 0 2px 20px rgba(0,0,0,.15);
  box-shadow: 0 2px 20px rgba(0,0,0,.15);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
.stpe-component_dropdown .stpe-dropdown-area .stpe-dropdown-box .icon-caret-up {
  position: absolute;
  top: -5px;
  left: 120px;
  margin-left: -10px;
  color: #fff;
  font-size: 21px;
}
.stpe-dropdown-box ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}
.stpe-component_dropdown .stpe-dropdown-area .stpe-dropdown-box ul li {
  float: none;
}
.stpe-component_dropdown .stpe-dropdown-area .stpe-dropdown-box.stpe-dropdown-text-center a {
  display: block;
  text-align: left;
}
.stpe-component_dropdown .stpe-dropdown-area .stpe-dropdown-box ul li a {
  display: block;
  padding: 7px 18px;
  text-align: left;
  text-transform: none;
  color: #777;
  font-size: 14px;
  letter-spacing: 0;
  -webkit-transition: all .15s ease-out 0s;
  -moz-transition: all .15s ease-out 0s;
  -o-transition: all .15s ease-out 0s;
  transition: all .15s ease-out 0s;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 99.9%;
  white-space: nowrap;
}
.stpe-dropdown-li {
  cursor: pointer;
}
.stpe-context_transactions-dialog form .input-row .drop-down-select-month a {
  font-size: 12px;
  line-height: 12px;
}
.stpe-dropdown-action {
  color: #18b272;
}
.stpe-invoice-active {
  background: #44e8a4;
}
.stpe-invoice-active a{
  color: #fff !important;
}
.mx-datepicker {
  width: 100% !important;
}
.alert-error {
  list-style: none;
  margin: 0px;
  padding: 15px;
}
.alert-error .alert {
  padding: 0;
  padding-left: 0px;
  padding-left: 8px;
  font-size: 13px;
  margin-bottom: 10px;
}
</style>
