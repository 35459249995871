<template>
  <div :id="modalId" :class="classes" :style="this.styles" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-md">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            Nova categoria
          </h5>
          <button type="button" class="close" @click="hide(true, true, form.limit)" aria-label="Close">
            <svg aria-hidden="true" style="width: 15px;" focusable="false" data-prefix="fas" data-icon="xmark" role="img"
              xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-xmark fa-xl">
              <path fill="currentColor"
                d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"
                class=""></path>
            </svg>
          </button>
        </div>
        <form @submit.prevent="create">
          <div class="modal-body">
            <div class="form-group">
              <div class="row">
                <div class="col-md-12">
                  <label>Nome</label>
                  <input type="text" class="form-control" name="category" placeholder="Nome" v-model="form.name"
                    required />
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-md-3 col-sm-3 col-xs-3">
                  <label class="m-top-0">Ícone</label>
                  <IconSelect :default="'baby-buggy'" :colorDefault="colorDefault" class="select"
                    @input="changeIcon($event)" />
                </div>
                <div class="col-md-3 col-sm-3 col-xs-3">
                  <label class="m-top-0">Cor</label>
                  <ColorSelect :default="'#7b93a4'" class="select" @input="changeColor($event)" />
                </div>
                <div class="col-md-6">
                  <div class="form-check" style="margin-top: 40px;">
                    <input class="form-check-input" type="radio" value="Income" checked="" name="type"
                      v-model="form.type" required id="categoryIncomeType" @change="changeType"/>
                    <label class="form-check-label inline" for="categoryIncomeType">
                      &nbsp;Receita
                    </label>
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    <input class="form-check-input" type="radio" name="type" checked="" value="Expense"
                      v-model="form.type" required id="categoryExpenseType" @change="changeType"/>
                    <label class="form-check-label inline" for="categoryExpenseType">
                      &nbsp;Despesa
                    </label>
                  </div>
                </div>
                <div class="col-md-12" v-if="subcategories.length > 0">
                  <hr class="divider-category">
                </div>
                <div class="col-md-12" v-if="subcategories.length > 0">
                  <label>Subcategorias</label>
                  <input type="hidden" name="icon" value="default">
                </div>
                <div class="col-md-12 " v-if="subcategories.length > 0">
                  <div class="card">
                    <div>
                      <ul class="ul-category-icon">
                        <li v-for="(item, i) in subcategories" :key="i" @click="choseSubcategorie(item)">
                          <div v-tooltip.top="item.name" class="ul-category-icon-item to-left" :style="`background: ${item.color}`" :class="{ active: item.selected }">
                            <img :src="`/assets/images/icons/png/${item.icon}.png`">
                            <i class="fa fa-check check-selected"></i>
                          </div>
                          <div style="clear: both;"></div>
                        </li>
                      </ul>
                    </div>
                    <div style="clear: both;"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-success btn-green-shadow"
              :class="{ 'qt-loader qt-loader-mini qt-loader-right': isSending }" :disabled="isSending">
              Salvar
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from "@/mixins/Modal";
import AppService from "@/services/app.service";
import IconSelect from '@/components/IconSelect';
import ColorSelect from '@/components/ColorSelect';

export default {
  mixins: [Modal],
  props: ["id"],
  components: {
    IconSelect,
    ColorSelect,
  },
  data() {
    return {
      colorDefault: '#7b93a4',
      form: {
        type: 'Expense',
        limit: 0
      },
      isSending: false,
      subcategories: [],
    };
  },
  created() {
    this.$forceUpdate();
    this.$root.$on('show.modal', this.detectData);
  },
  methods: {
    changeType() {
      this.requestSubcategories();
    },
    detectData(id, data) {
      if(data && id == 'create-category-modal') {
        this.form.type = this.formatType(data.type);
        this.requestSubcategories();
      }
    },
    formatType(type) {
      let formSurname = '';
      if (type) {
        formSurname = type.toString()
        formSurname = formSurname.charAt(0).toUpperCase() + formSurname.slice(1)
      }
      return formSurname;
    },
    changeColor(color) {
      this.colorDefault = color;
      this.form.color = color;
    },
    requestSubcategories() {
      AppService.getSubCategories(this.form.type).then(
        (response) => {
          this.subcategories = response.categories;
          this.isLoading = false;
        },
        (error) => {
          console.log(error);
          this.content = error;
        }
      );
    },
    changeIcon(icon) {
      this.form.icon = icon;
    },
    choseSubcategorie(item) {
      item.selected = !item.selected;
      console.log(item);
      this.$forceUpdate();
    },
    create() {
      this.isSending = true;
      let subcategoriesSave = [];
      this.subcategories.forEach(category => {
        if(category.selected) {
          subcategoriesSave.push(category);
        }
      });
      console.log(this.form);
      this.form.subcategories = subcategoriesSave;
      AppService.addCategory(this.form).then(
        (response) => {
          this.isSending = false;
          console.log(response);
          this.$toast.show({
            title: "Sucesso",
            content: "Categoria registrada!",
            type: "success",
          });
          this.$emit("save");
          this.$root.$emit('save.category', { category: response.category});
          this.hide(true);
        },
        (error) => {
          console.log(error);
          this.content = error;
          this.isSending = false;
          this.$toast.show({
            title: "Erro",
            content: "Erro interno, por favor tente novamente mais tarde",
            type: "error",
          });
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
textarea:not(:last-child) {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.textarea-information {
  border: 1px solid #dfe7f3;
  border-top: none;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  padding: 0.2rem 0.5rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

textarea:focus+.textarea-information {
  border: 1px solid #635ebe;
  border-top: none;
}

.actions-var {
  position: relative !important;
}

.emoji-color {
  color: #ccd2dc !important;
}

.actions-var-cancel {
  text-align: center;
  color: #4d5a68;
  border-top: 1px solid rgba(234, 243, 253, 0.7);
  padding-top: 5px;
  padding-bottom: 0px;
}

.actions-icon {
  font-size: 14px !important;
}

.actions-var a {
  cursor: pointer;
}

.fr-wrapper>*:first-child {
  display: none;
}

.fr-wrapper .fr-element {
  display: block;
}

.second-toolbar a {
  visibility: hidden;
}

#cke_673 {
  display: none !important;
}

.cke_toolbar_last {
  display: none;
}

.modal-body {
  padding: 25px;
}

.popup-icons {
  height: 50px;
}

.popup-icons .stpe-icons-center {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 64px;
  margin: auto;
}

.popup-icons .stpe-icons-center a.stpe-logo-banks {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  background: #fff;
  overflow: hidden;
  cursor: default;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.popup-icons .stpe-icons-center a.stpe-logo-banks img {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 60px;
  height: 60px;
  margin: auto;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.stpe-component_dropdown .stpe-dropdown-area {
  display: none;
  position: absolute;
  top: 15px;
  left: 120px;
  width: 190px;
  margin-top: 4px;
  margin-left: -95px;
  padding-top: 12px;
  z-index: 2;
}

.drop-down-select-month:hover .stpe-dropdown-area {
  display: block;
}

.stpe-component_dropdown .stpe-dropdown-area .stpe-dropdown-box {
  background: #fff;
  -webkit-box-shadow: 0 2px 20px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 2px 20px rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.15);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

.stpe-component_dropdown .stpe-dropdown-area .stpe-dropdown-box .icon-caret-up {
  position: absolute;
  top: -5px;
  left: 120px;
  margin-left: -10px;
  color: #fff;
  font-size: 21px;
}

.stpe-dropdown-box ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.stpe-component_dropdown .stpe-dropdown-area .stpe-dropdown-box ul li {
  float: none;
}

.stpe-component_dropdown .stpe-dropdown-area .stpe-dropdown-box.stpe-dropdown-text-center a {
  display: block;
  text-align: left;
}

.stpe-component_dropdown .stpe-dropdown-area .stpe-dropdown-box ul li a {
  display: block;
  padding: 7px 18px;
  text-align: left;
  text-transform: none;
  color: #777;
  font-size: 14px;
  letter-spacing: 0;
  -webkit-transition: all 0.15s ease-out 0s;
  -moz-transition: all 0.15s ease-out 0s;
  -o-transition: all 0.15s ease-out 0s;
  transition: all 0.15s ease-out 0s;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 99.9%;
  white-space: nowrap;
}

.stpe-dropdown-li {
  cursor: pointer;
}

.stpe-context_transactions-dialog form .input-row .drop-down-select-month a {
  font-size: 12px;
  line-height: 12px;
}

.stpe-dropdown-action {
  color: #18b272;
}

.stpe-invoice-active {
  background: #44e8a4;
}

.stpe-invoice-active a {
  color: #fff !important;
}
</style>
