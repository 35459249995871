<template>
  <div
    :id="modalId"
    :class="classes"
    :style="this.styles"
    tabindex="-1"
    role="dialog"
  >
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Novo cartão de crédito</h5>
          <button type="button" class="close" @click="hide" aria-label="Close">
            <svg aria-hidden="true" style="width: 15px;" focusable="false" data-prefix="fas" data-icon="xmark" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-xmark fa-xl"><path fill="currentColor" d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" class=""></path></svg>
          </button>
        </div>
        <form @submit.prevent="create">
          <div class="modal-body">
            <div class="form-group">
              <div class="row" v-if="stepForm == 'start'">
                <div class="col-md-12 hide">
                  <div class="slide-tab-header">
                    <slide-tab firstName="Manual" lastName="Automática" tabPositionMax="50" tabCustom="tab-custom" @changeTabCallBack="changeTab"/>
                  </div>
                  <hr>
                </div>
                <div class="col-md-12">
                  <label>Bandeira</label>
                  <CardSelect
                    :default="{ sig: 'visa', name: 'Visa', id: 1 }"
                    class="select"
                    @input="changeBrand($event)"
                  />
                </div>
              </div>
              <div class="row m-top-10" v-if="stepForm == 'start'">
                <div class="col-md-12">
                  <label>Nome</label>
                  <input
                    v-model="form.name"
                    type="text"
                    class="form-control"
                    required
                  />
                  <p class="input-info">Informe um nome para identificar seu cartão.</p>
                </div>
              </div>
              <div class="row" v-if="stepForm == 'start'">
                  <div class="col-md-12">
                    <label>Limite</label>
                    <money v-model="form.card_limit" v-bind="money" class="form-control"></money> 
                  </div>
              </div>
              <div class="row m-top-10" v-if="stepForm == 'start'">
                <div class="col-md-6">
                  <label>Fecha dia</label>
                  <select
                    v-model="form.invoice_day"
                    type="text"
                    class="form-control"
                    required
                  >
                    <option label="1">1</option>
                    <option label="2">2</option>
                    <option label="3">3</option>
                    <option label="4">4</option>
                    <option label="5">5</option>
                    <option label="6">6</option>
                    <option label="7">7</option>
                    <option label="8">8</option>
                    <option label="9">9</option>
                    <option label="10">10</option>
                    <option label="11">11</option>
                    <option label="12">12</option>
                    <option label="13">13</option>
                    <option label="14">14</option>
                    <option label="15">15</option>
                    <option label="16">16</option>
                    <option label="17">17</option>
                    <option label="18">18</option>
                    <option label="19">19</option>
                    <option label="20">20</option>
                    <option label="21">21</option>
                    <option label="22">22</option>
                    <option label="23">23</option>
                    <option label="24">24</option>
                    <option label="25">25</option>
                    <option label="26">26</option>
                    <option label="27">27</option>
                    <option label="28">28</option>
                    <option label="29">29</option>
                    <option label="30">30</option>
                    <option label="31">31</option>
                  </select>
                </div>
                <div class="col-md-6">
                  <label>Vence dia</label>
                  <select
                    v-model="form.payment_day"
                    type="text"
                    class="form-control"
                    required
                  >
                    <option label="1">1</option>
                    <option label="2">2</option>
                    <option label="3">3</option>
                    <option label="4">4</option>
                    <option label="5">5</option>
                    <option label="6">6</option>
                    <option label="7">7</option>
                    <option label="8">8</option>
                    <option label="9">9</option>
                    <option label="10">10</option>
                    <option label="11">11</option>
                    <option label="12">12</option>
                    <option label="13">13</option>
                    <option label="14">14</option>
                    <option label="15">15</option>
                    <option label="16">16</option>
                    <option label="17">17</option>
                    <option label="18">18</option>
                    <option label="19">19</option>
                    <option label="20">20</option>
                    <option label="21">21</option>
                    <option label="22">22</option>
                    <option label="23">23</option>
                    <option label="24">24</option>
                    <option label="25">25</option>
                    <option label="26">26</option>
                    <option label="27">27</option>
                    <option label="28">28</option>
                    <option label="29">29</option>
                    <option label="30">30</option>
                    <option label="31">31</option>
                  </select>
                </div>
              </div>
              <div class="row m-top-10" v-if="stepForm == 'start'">
                <div class="col-md-12 hide">
                  <hr>
                  <input type="checkbox" v-model="form.gen_bill" class="to-left" id="gen_bill">
                  <label style="margin-top: 10px;" for="gen_bill" class="to-left">Gerar despesa da fatura</label>
                </div>
              </div>
              <div class="row m-top-10" v-if="stepForm == 'start'">
                <div class="col-md-12">
                  <label>Conta padrão de pagamento</label>
                  <select-input
                    label="name"
                    :reduce="(field) => field.id"
                    v-model="form.account"
                    :options="accounts"
                    :clearable="true"
                  />
                  <a class="font-13 btn-call-side hide"><i class="mdi mdi-plus-circle-outline add-plus"></i> Cadastrar nova conta</a>
                  <p class="input-info">Conta ou carteira da qual será efetuado o débido da fatura.</p>
                </div>
              </div>
              <div class="row" v-if="stepForm == 'finish'">
                <div class="col-md-12 m-top-10" style="margin-bottom: 10px;">
                  <label>Banco</label>
                  <BankSelect
                    :default="{ sig: 'nubank', name: 'Nubank', id: 1 }"
                    class="select"
                  />
                </div>
              </div>
              <div class="row" v-if="stepForm == 'finish'">
                <div class="col-md-12" style="margin-top: 10px;">
                  <h4 >Dados de acesso</h4>
                </div>
              </div>
              <div class="row" v-if="stepForm == 'finish'">
                <div class="col-md-12">
                  <hr style="margin-top: 0px;margin-bottom: 10px;">
                </div>
              </div>
              <div class="row" v-if="stepForm == 'finish' && stage == 'start'">
                <div class="col-md-12">
                  <label>CPF</label>
                  <input
                    type="text"
                    class="form-control"
                    name="category"
                    placeholder="Digite o CPF"
                    v-mask="'###.###.###-##'"
                    v-model="form.cpf"
                  />
                </div>
              </div>
              <div class="row" v-if="stepForm == 'finish' && stage == 'start'">
                <div class="col-md-12 m-top-10">
                  <label>Senha</label>
                  <input
                    type="text"
                    class="form-control"
                    name="category"
                    placeholder="Digite a senha"
                    v-model="form.password"
                  />
                </div>
              </div>
              <div class="row" v-if="stepForm == 'finish' && stage == 'finish'">
                <div class="col-md-12 m-top-10">
                  <label>Código</label>
                  <input
                    type="text"
                    class="form-control"
                    name="category"
                    placeholder="Digite o código"
                    v-model="form.code"
                  />
                </div>
              </div>
              <div class="row" v-if="stepForm == 'finish'">
                <div class="col-md-12">
                  <p class="bank-info">
                    Para a sua segurança nós não armazenamos os seus dados de acesso do banco.
                  </p>
                </div>
              </div>
              <div class="row" v-if="stepForm == 'finish' && stage == 'finish'">
                <div class="col-md-12">
                  <p class="bank-info">
                    Digite o código enviado no seu email
                  </p>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-info btn-info-shadow"
            @click="stepForm = 'start'"
            v-if="accountType == 1 && stepForm == 'finish'"
          >
            Voltar
          </button>
          <button
            @click="create"
            class="btn btn-success btn-green-shadow"
            :class="{ 'qt-loader qt-loader-mini qt-loader-right': isSending }"
            :disabled="isSending"
            v-if="accountType == 0 || stepForm == 'finish'"
          >
            Salvar
          </button>
          <button
            type="button"
            class="btn btn-info btn-info-shadow"
            @click="stepForm = 'finish'"
            v-if="accountType == 1 && stepForm == 'start'"
          >
            Avançar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from '@/mixins/Modal';
import CardSelect from '@/components/CardSelect';
import { Money } from 'v-money'
import AppService from '@/services/app.service';
import SelectInput from '@/components/SelectInput.vue';
import SlideTab from '@/components/SlideTab';
import BankSelect from '@/components/BankSelect';
import Swal from 'sweetalert2';

export default {
  mixins: [Modal],
  props: ['id'],
  components: {
    Money,
    CardSelect,
    SelectInput,
    SlideTab,
    BankSelect,
  },
  data() {
    return {
      stage: 'start',
      stepForm: 'start',
      miniTab: {
        tab: 0,
        indicator: 0,
      },
      colorDefault: '#7b93a4',
      accountType: 0,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false
      },
      form: {
        brand: { sig: 'visa', name: 'Visa', id: 1 },
      },
      isSending: false,
      accounts: [],
    };
  },
  created() {
    AppService.getWallets({}).then(
      (response) => {
        this.accounts = response.wallets;
      },
      (error) => {
        console.log(error);
        this.content = error;
      },
    );
    this.Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });
  },
  methods: {
    changeTab(tab) {
      this.miniTab.tab = tab;
      this.accountType = tab;
    },
    changeBrand(brand) {
      this.form.brand = brand;
    },
    create() {
      console.log(this.form);
      this.isSending = true;
      switch (this.accountType) {
        case 0:
          AppService.addCard(this.form).then(
            (response) => {
              this.isSending = false;
              console.log(response);
              this.Toast.fire({
                icon: 'success',
                title: 'Cartão cadastrado com sucesso!',
              });
              this.$root.$emit('register.card', {
                card: response.card
              });
              this.hide();
            },
            (error) => {
              console.log(error);
              this.content = error;
              this.isSending = false;
              this.Toast.fire({
                timerProgressBar: false,
                icon: 'error',
                title: 'Erro interno, por favor tente novamente mais tarde',
              });
            },
          );
          break;
        case 1:
          if(this.stage == 'start') {
            AppService.getNubankToken(this.form).then(
              (response) => {
                this.isSending = false;
                let jsonString = JSON.stringify(response.data);
                jsonString = jsonString.replace('encrypted-code', 'encryptedCode');
                let jsonRest = JSON.parse(jsonString);
                this.form.encrypted_code = jsonRest.encryptedCode;
                this.stage = 'finish';
              },
              (error) => {
                console.log(error);
                this.content = error;
                this.isSending = false;
                this.Toast.fire({
                  timerProgressBar: false,
                  icon: 'error',
                  title: 'Erro interno, por favor tente novamente mais tarde',
                });
              }
            );
          } else {
            AppService.getNubankCertificateCard(this.form).then(
              (response) => {
                this.isSending = false;
                this.$router.push(`/card/detail/${response.data.id}`);
                this.$root.$emit('register.card');
                this.Toast.fire({
                  icon: 'success',
                  title: 'Cartão cadastrado com sucesso!',
                });
                this.hide();
              },
              (error) => {
                console.log(error);
                this.content = error;
                this.isSending = false;
                this.Toast.fire({
                  timerProgressBar: false,
                  icon: 'error',
                  title: 'Erro interno, por favor tente novamente mais tarde',
                });
              }
            );
          }
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bank-info {
  font-size: 13px;
  color: #b7b7b7;
  line-height: normal;
  margin-top: 10px;
}
textarea:not(:last-child) {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.textarea-information {
  border: 1px solid #dfe7f3;
  border-top: none;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  padding: 0.2rem 0.5rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
textarea:focus + .textarea-information {
  border: 1px solid #635ebe;
  border-top: none;
}

.actions-var {
  position: relative !important;
}
.emoji-color {
  color: #ccd2dc !important;
}
.actions-var-cancel {
  text-align: center;
  color: #4d5a68;
  border-top: 1px solid rgba(234, 243, 253, 0.7);
  padding-top: 5px;
  padding-bottom: 0px;
}
.actions-icon {
  font-size: 14px !important;
}
.actions-var a {
  cursor: pointer;
}
.fr-wrapper > *:first-child {
  display: none;
}
.fr-wrapper .fr-element {
  display: block;
}
.second-toolbar a {
  visibility: hidden;
}
#cke_673 {
  display: none !important;
}
.cke_toolbar_last {
  display: none;
}
.modal-body {
  padding: 25px;
}
.popup-icons {
  height: 50px;
}
.popup-icons .zze-icons-center {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 64px;
  margin: auto;
}
.popup-icons .zze-icons-center a.zze-logo-banks {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  background: #fff;
  overflow: hidden;
  cursor: default;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.popup-icons .zze-icons-center a.zze-logo-banks img {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 60px;
  height: 60px;
  margin: auto;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
</style>
