<template>
  <div>
    <Toolbar />
    <div class="container">
      <router-view />
      <Footer />
    </div>
    <create-transaction-modal id="create-transaction-modal"/>
    <edit-transaction-modal id="edit-transaction-modal"/>
    <create-card-modal id="create-card-modal"/>
    <create-account-modal id="create-account-modal"/>
    <category-detail-modal id="category-detail-modal"/>
    <create-category-modal id="create-category-modal"/>
    <create-provider-modal id="create-provider-modal"/>
    <create-client-modal id="create-client-modal"/>
  </div>
</template>

<script>
import Toolbar from '@/components/Toolbar.vue';
import Footer from '@/components/Footer.vue';
import CreateTransactionModal from '@/components/CreateTransactionModal.vue';
import EditTransactionModal from '@/components/EditTransactionModal.vue';
import CreateCardModal from '@/components/cards/CreateCardModal.vue';
import CreateAccountModal from '@/components/CreateAccountModal.vue';
import CategoryDetailModal from '@/components/CategoryDetailModal.vue';
import CreateCategoryModal from '@/components/CreateCategoryModal.vue';
import CreateProviderModal from '@/components/CreateProviderModal.vue';
import CreateClientModal from '@/components/CreateClientModal.vue';

export default {
  name: "Layout",
  components: {
    Toolbar,
    Footer,
    CreateTransactionModal,
    EditTransactionModal,
    CreateCardModal,
    CreateAccountModal,
    CategoryDetailModal,
    CreateCategoryModal,
    CreateProviderModal,
    CreateClientModal,
  },
  computed: {
    classes() {
      return ['wizard'];
    },
    info() {
      return JSON.parse(localStorage.getItem('stimper_user'));
    },
  },
  data() {
    return {
    };
  },
  provide: {
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
